import React, { useState } from 'react'
import { Link } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image'

import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { useMediaQuery } from 'usehooks-ts'
import Seo from '../../components/seo'
import Layout from '../../components/layout'

import KlaviyoForm from '../../components/utilities/klaviyo-form'

const SMS = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)')

  return (
    <Layout className="sms-page sms-page__biggame">
      <Seo title="Root Root!" noindex />
      <Container fluid className="stack-parent px-0">
        <div className="stack-child">
          <Row>
            <Col className="bg-primary" />
            <Col className="bg-warning" />
          </Row>
        </div>
        <div className="stack-child py-2">
          <header className="text-center text-white mb-1">
            <h1>
              <span className="d-block mb-1 mb-md-12">Root Root</span>
              <b>...for your team</b>
            </h1>
          </header>
          <div className="stack-parent">
            <Container className="stack-child" fluid="lg">
              <Row className="g-0 my-n1">
                <Col>
                  { isDesktop
                    ? (
                      <StaticImage
                        src="../../images/sms/crispy-assets-2.png"
                        quality={95}
                        placeholder="blurred"
                        alt="Crispy Stickers"
                        layout="fullWidth"
                        className="sms-background-stickers sms-background-stickers__left"
                      />
                    )
                    : (
                      <StaticImage
                        src="../../images/sms/crispy-assets.png"
                        quality={95}
                        placeholder="blurred"
                        alt="Crispy Stickers"
                        layout="fullWidth"
                        className="sms-background-stickers sms-background-stickers__left"
                      />
                    )}
                </Col>
                <Col className="d-none" />
                <Col>
                  { isDesktop
                    ? (
                      <StaticImage
                        src="../../images/sms/classic-assets-2.png"
                        quality={95}
                        placeholder="blurred"
                        alt="Classic Stickers"
                        layout="fullWidth"
                        className="sms-background-stickers sms-background-stickers__right"
                      />
                    )
                    : (
                      <StaticImage
                        src="../../images/sms/classic-assets.png"
                        quality={95}
                        placeholder="blurred"
                        alt="Classic Stickers"
                        layout="fullWidth"
                        className="sms-background-stickers sms-background-stickers__right"
                      />
                    )}
                </Col>
              </Row>
            </Container>
            <Container className="stack-child" fluid="lg">
              <Row>
                <Col md="2" lg="3" className="d-none d-md-block" />
                <Col className="sms-form-column">
                  <div className="px-2 sms-form-container">
                    <div className="rounded-5 border border-2 border-dark position-relative">
                      <KlaviyoForm code="T7dMkQ" />
                      <StaticImage
                        src="../../images/sms/crispy-mo.png"
                        quality={95}
                        placeholder="blurred"
                        alt="Crispy Mo"
                        layout="constrained"
                        className="position-absolute sms-sticker sms-sticker__crispy-mo"
                      />
                      <StaticImage
                        src="../../images/sms/classic-flag.png"
                        quality={95}
                        placeholder="blurred"
                        alt="Classic Flag"
                        layout="constrained"
                        className="position-absolute sms-sticker sms-sticker__classic-flag"
                      />
                      <StaticImage
                        src="../../images/sms/go-spores.png"
                        quality={95}
                        placeholder="blurred"
                        alt="Go Spores Flag"
                        layout="constrained"
                        className="position-absolute sms-sticker sms-sticker__go-spores"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="2" lg="3" className="d-none d-md-block" />
              </Row>
            </Container>
          </div>

        </div>
      </Container>
    </Layout>
  )
}

export default SMS
